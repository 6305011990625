import {
    Abstract
} from './Abstract';

import axios from 'axios';

export class BWCybersourceInstallmentConfiguration extends Abstract { 
    constructor(session) {
        super('BusinessWallet/CyberSource/Configuration/installment', session);
        this.three_months = false;
        this.six_months = false;
        this.nine_months = false;
        this.twelve_months = false;
        this.eighteen_months = false;
        this.twentyfour_months = false;
    }

    async retrieveByBranch() {
        let response = await axios.get(this.controller + "/" + this.id_company + "/" + this.id_branch);
        return response.data;
    }
}