<template>
    <div class="grid crud-demo">
        <Loader v-model="loading" />
        <Toast />
        <div class="col-12">
            <div class="card">
                <Panel header="Configuracion">
                    <BasicFormToolbar @save="save" :actions="['save']" />
                    <TabView ref="tab">
                        <TabPanel header="Informacion de Procesador">
                            <div class="p-fluid formgrid grid">
                                <div class="col-12">
                                    <Fieldset :toggleable="true" legend="PROSA" :collapsed="true">
                                        <div class="p-fluid formgrid grid">
                                            <div class="col-12">
                                                <div class="p-fluid formgrid grid">
                                                <FormInputText wrapperClass="field col-4" v-model="processor_prosa.merchantID" label="Merchant ID" />
                                                <FormInputText wrapperClass="field col-4" v-model="processor_prosa.merchantKeyId" label="Merchant Key ID" />
                                                <FormInputText wrapperClass="field col-4" v-model="processor_prosa.merchantsecretKey" label="Merchant Secret Key" />
                                                <FormDropdown
                                                    wrapperClass="field col-4"
                                                    v-model="processor_prosa.useMetaKey"
                                                    label="Use Metakey"
                                                    optionLabel="text"
                                                    optionValue="value"
                                                    :options="[
                                                        { value: 'true', text: 'Si' },
                                                        { value: 'false', text: 'No' },
                                                    ]"
                                                />
                                                <FormDropdown
                                                    wrapperClass="field col-4"
                                                    v-model="processor_prosa.runEnvironment"
                                                    label="Environment"
                                                    optionLabel="text"
                                                    optionValue="value"
                                                    :options="[
                                                        { value: 'apitest.cybersource.com', text: 'Pruebas' },
                                                        { value: 'api.cybersource.com', text: 'Produccion' },
                                                    ]"
                                                />
                                                <FormInputText wrapperClass="field col-4" v-model="processor_prosa.portfolioID" label="Portfolio ID" />
                                           
                                            <FormDropdown :wrapperClass="'col-12 field'" v-model="processor_prosa.sponsor_bank" label="Banco Sponsor" :options="bank" :optionLabel="'marca'" :optionValue="'marca'" />
                             
                                            </div>
                                            </div>
                                            <div class="col-6">
                                                <Fieldset :toggleable="true">
                                                    <template #legend>
                                                        <img :src="require('../../../assets/icons/payment/VISA.png')" class="ddl-card-icon" />
                                                        <img :src="require('../../../assets/icons/payment/MASTERCARD.png')" class="ddl-card-icon" /> Visa / Mastercard
                                                    </template>
                                                    <TabView ref="tab">
                                                    <TabPanel header="Comision">
                                                    <div class="p-fluid formgrid grid">
                                                        <FormInputNumber :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :prefix="'%'" wrapperClass="field col-6" label="Debito" v-model="processor_prosa.visa_mc_debit_rate" />
                                                        <FormInputNumber :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :prefix="'%'" wrapperClass="field col-6" label="Credito" v-model="processor_prosa.visa_mc_credit_rate" />
                                                        <FormInputNumber :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :prefix="'%'" wrapperClass="field col-3" label="3 MSI" v-model="processor_prosa.visa_mc_credit_surcharge_3" />
                                                        <FormInputNumber :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :prefix="'%'" wrapperClass="field col-3" label="6 MSI" v-model="processor_prosa.visa_mc_credit_surcharge_6" />
                                                        <FormInputNumber :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :prefix="'%'" wrapperClass="field col-3" label="9 MSI" v-model="processor_prosa.visa_mc_credit_surcharge_9" />
                                                        <FormInputNumber :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :prefix="'%'" wrapperClass="field col-3" label="12 MSI" v-model="processor_prosa.visa_mc_credit_surcharge_12" />
                                                        <FormInputNumber :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :prefix="'%'" wrapperClass="field col-3" label="18 MSI" v-model="processor_prosa.visa_mc_credit_surcharge_18" />
                                                        <FormInputNumber :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :prefix="'%'" wrapperClass="field col-3" label="24 MSI" v-model="processor_prosa.visa_mc_credit_surcharge_24" />

                                                    </div>
                                                     </TabPanel>
                                                          <TabPanel header="Afiliacion">
                                                    <div class="p-fluid formgrid grid">
                                                        <FormInputText wrapperClass="field col-6" label="# Debito" v-model="processor_prosa.visa_mc_debit_rate_affiliation" />
                                                        <FormInputText wrapperClass="field col-6" label="# Credito" v-model="processor_prosa.visa_mc_credit_rate_affiliation" />
                                                        <FormInputText wrapperClass="field col-3" label="#Q6" v-model="processor_prosa.visa_mc_credit_surcharge_3_affiliation" />
                                                        <FormInputText v-show="false" wrapperClass="field col-3" label="# 6 MSI" v-model="processor_prosa.visa_mc_credit_surcharge_6_affiliation" />
                                                        <FormInputText v-show="false" wrapperClass="field col-3" label="# 9 MSI" v-model="processor_prosa.visa_mc_credit_surcharge_9_affiliation" />
                                                        <FormInputText v-show="false" wrapperClass="field col-3" label="# 12 MSI" v-model="processor_prosa.visa_mc_credit_surcharge_12_affiliation" />
                                                        <FormInputText v-show="false" wrapperClass="field col-3" label="# 18 MSI" v-model="processor_prosa.visa_mc_credit_surcharge_18_affiliation" />
                                                        <FormInputText v-show="false" wrapperClass="field col-3" label="# 24 MSI" v-model="processor_prosa.visa_mc_credit_surcharge_24_affiliation" />

                                                    </div>
                                                     </TabPanel>
                                                        </TabView>
                                                </Fieldset>
                                            </div>
                                            <div class="col-6">
                                                <Fieldset :toggleable="true">
                                                    <template #legend> <img :src="require('../../../assets/icons/payment/AMERICANEXPRESS.png')" class="ddl-card-icon" /> American Express </template>
                                                    
                                                           <TabView ref="tab">
                                                    <TabPanel header="Comision">
                                                    <div class="p-fluid formgrid grid">
                                                          <FormInputNumber :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :prefix="'%'" wrapperClass="field col-12" label="Credito" v-model="processor_prosa.amex_credit_rate" />
                                                        <FormInputNumber :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :prefix="'%'" wrapperClass="field col-3" label="3 MSI" v-model="processor_prosa.amex_credit_surcharge_3" />
                                                        <FormInputNumber :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :prefix="'%'" wrapperClass="field col-3" label="6 MSI" v-model="processor_prosa.amex_credit_surcharge_6" />
                                                        <FormInputNumber :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :prefix="'%'" wrapperClass="field col-3" label="9 MSI" v-model="processor_prosa.amex_credit_surcharge_9" />
                                                        <FormInputNumber :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :prefix="'%'" wrapperClass="field col-3" label="12 MSI" v-model="processor_prosa.amex_credit_surcharge_12" />
                                                        <FormInputNumber :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :prefix="'%'" wrapperClass="field col-3" label="18 MSI" v-model="processor_prosa.amex_credit_surcharge_18" />
                                                        <FormInputNumber :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :prefix="'%'" wrapperClass="field col-3" label="24 MSI" v-model="processor_prosa.amex_credit_surcharge_24" />
                                                   
                                                    </div>
                                                     </TabPanel>
                                                          <TabPanel header="Afiliacion">
                                                    <div class="p-fluid formgrid grid">
                                            <FormInputText wrapperClass="field col-12" label="# Credito" v-model="processor_prosa.amex_credit_rate_affiliation" />
                                                        <FormInputText wrapperClass="field col-3" label="#Q6" v-model="processor_prosa.amex_credit_surcharge_3_affiliation" />
                                                        <FormInputText v-show="false" wrapperClass="field col-3" label="# 6 MSI" v-model="processor_prosa.amex_credit_surcharge_6_affiliation" />
                                                        <FormInputText v-show="false"  wrapperClass="field col-3" label="# 9 MSI" v-model="processor_prosa.amex_credit_surcharge_9_affiliation" />
                                                        <FormInputText v-show="false" wrapperClass="field col-3" label="# 12 MSI" v-model="processor_prosa.amex_credit_surcharge_12_affiliation" />
                                                        <FormInputText v-show="false" wrapperClass="field col-3" label="# 18 MSI" v-model="processor_prosa.amex_credit_surcharge_18_affiliation" />
                                                        <FormInputText v-show="false" wrapperClass="field col-3" label="# 24 MSI" v-model="processor_prosa.amex_credit_surcharge_24_affiliation" />
                                                   
                                                    </div>
                                                     </TabPanel>
                                                        </TabView>
                                           
                                                </Fieldset>
                                            </div>
                                        </div>
                                    </Fieldset>
                                </div>
                                <div class="col-12" style="margin-top: 1rem">
                                    <Fieldset :toggleable="true" legend="E GLOBAL BANCOMER" :collapsed="true">
                                        <div class="p-fluid formgrid grid">
                                            <Button @click="copyBancomer" label="Copiar datos de PROSA" icon="pi pi-copy" />
                                            <Divider />
                                            <FormInputText wrapperClass="field col-4" v-model="processor_eglobal_bancomer.merchantID" label="Merchant ID" />
                                            <FormInputText wrapperClass="field col-4" v-model="processor_eglobal_bancomer.merchantKeyId" label="Merchant Key ID" />
                                            <FormInputText wrapperClass="field col-4" v-model="processor_eglobal_bancomer.merchantsecretKey" label="Merchant Secret Key" />
                                            <FormDropdown
                                                wrapperClass="field col-4"
                                                v-model="processor_eglobal_bancomer.useMetaKey"
                                                label="Use Metakey"
                                                optionLabel="text"
                                                optionValue="value"
                                                :options="[
                                                    { value: 'true', text: 'Si' },
                                                    { value: 'false', text: 'No' },
                                                ]"
                                            />
                                            <FormDropdown
                                                wrapperClass="field col-4"
                                                v-model="processor_eglobal_bancomer.runEnvironment"
                                                label="Environment"
                                                optionLabel="text"
                                                optionValue="value"
                                                :options="[
                                                    { value: 'apitest.cybersource.com', text: 'Pruebas' },
                                                    { value: 'api.cybersource.com', text: 'Produccion' },
                                                ]"
                                            />
                                            <FormInputText wrapperClass="field col-4" v-model="processor_eglobal_bancomer.portfolioID" label="Portfolio ID" />
                                       <FormDropdown :wrapperClass="'col-12 field'" v-model="processor_eglobal_bancomer.sponsor_bank" label="Banco Sponsor" :options="bank" :optionLabel="'marca'" :optionValue="'marca'" />
                                        </div>
                                        <div class="p-fluid formgrid grid">
                                            <div class="col-6">
                                                <Fieldset :toggleable="true">
                                                    <template #legend>
                                                        <img :src="require('../../../assets/icons/payment/VISA.png')" class="ddl-card-icon" />
                                                        <img :src="require('../../../assets/icons/payment/MASTERCARD.png')" class="ddl-card-icon" /> Visa / Mastercard
                                                    </template>
                                                        <TabView ref="tab">
                                                    <TabPanel header="Comision">
                                                    <div class="p-fluid formgrid grid">
                                                    <FormInputNumber :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :prefix="'%'" wrapperClass="field col-6" label="Debito" v-model="processor_eglobal_bancomer.visa_mc_debit_rate" />
                                                        <FormInputNumber :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :prefix="'%'" wrapperClass="field col-6" label="Credito" v-model="processor_eglobal_bancomer.visa_mc_credit_rate" />
                                                        <FormInputNumber :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :prefix="'%'" wrapperClass="field col-3" label="3 MSI" v-model="processor_eglobal_bancomer.visa_mc_credit_surcharge_3" />
                                                        <FormInputNumber :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :prefix="'%'" wrapperClass="field col-3" label="6 MSI" v-model="processor_eglobal_bancomer.visa_mc_credit_surcharge_6" />
                                                        <FormInputNumber :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :prefix="'%'" wrapperClass="field col-3" label="9 MSI" v-model="processor_eglobal_bancomer.visa_mc_credit_surcharge_9" />
                                                        <FormInputNumber :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :prefix="'%'" wrapperClass="field col-3" label="12 MSI" v-model="processor_eglobal_bancomer.visa_mc_credit_surcharge_12" />
                                                        <FormInputNumber :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :prefix="'%'" wrapperClass="field col-3" label="18 MSI" v-model="processor_eglobal_bancomer.visa_mc_credit_surcharge_18" />
                                                        <FormInputNumber :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :prefix="'%'" wrapperClass="field col-3" label="24 MSI" v-model="processor_eglobal_bancomer.visa_mc_credit_surcharge_24" />
                                                   
                                                    </div>
                                                     </TabPanel>
                                                          <TabPanel header="Afiliacion">
                                                    <div class="p-fluid formgrid grid">
                                                       <FormInputText wrapperClass="field col-6" label=" # Debito" v-model="processor_eglobal_bancomer.visa_mc_debit_rate_affiliation" />
                                                    <FormInputText  wrapperClass="field col-6" label="# Credito" v-model="processor_eglobal_bancomer.visa_mc_credit_rate_affiliation" />
                                                    <FormInputText  wrapperClass="field col-3" label="#Q6" v-model="processor_eglobal_bancomer.visa_mc_credit_surcharge_3_affiliation" />
                                                    <FormInputText v-show="false"  wrapperClass="field col-3" label="# 6 MSI" v-model="processor_eglobal_bancomer.visa_mc_credit_surcharge_6_affiliation" />
                                                    <FormInputText  v-show="false" wrapperClass="field col-3" label="# 9 MSI" v-model="processor_eglobal_bancomer.visa_mc_credit_surcharge_9_affiliation" />
                                                    <FormInputText v-show="false" wrapperClass="field col-3" label="# 12 MSI" v-model="processor_eglobal_bancomer.visa_mc_credit_surcharge_12_affiliation" />
                                                    <FormInputText v-show="false" wrapperClass="field col-3" label="# 18 MSI" v-model="processor_eglobal_bancomer.visa_mc_credit_surcharge_18_affiliation" />
                                                    <FormInputText  v-show="false" wrapperClass="field col-3" label="# 24 MSI" v-model="processor_eglobal_bancomer.visa_mc_credit_surcharge_24_affiliation" />
                                                  
                                                    </div>
                                                     </TabPanel>
                                                        </TabView>
                                                  
                                                </Fieldset>
                                            </div>
                                            <div class="col-6">
                                                <Fieldset :toggleable="true">
                                                    <template #legend> <img :src="require('../../../assets/icons/payment/AMERICANEXPRESS.png')" class="ddl-card-icon" /> American Express </template>
                                                     <TabView ref="tab">
                                                    <TabPanel header="Comision">
                                                    <div class="p-fluid formgrid grid">
                                                     <FormInputNumber :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :prefix="'%'" wrapperClass="field col-12" label="Credito" v-model="processor_eglobal_bancomer.amex_credit_rate" />
                                                        <FormInputNumber :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :prefix="'%'" wrapperClass="field col-3" label="3 MSI" v-model="processor_eglobal_bancomer.amex_credit_surcharge_3" />
                                                        <FormInputNumber :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :prefix="'%'" wrapperClass="field col-3" label="6 MSI" v-model="processor_eglobal_bancomer.amex_credit_surcharge_6" />
                                                        <FormInputNumber :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :prefix="'%'" wrapperClass="field col-3" label="9 MSI" v-model="processor_eglobal_bancomer.amex_credit_surcharge_9" />
                                                        <FormInputNumber :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :prefix="'%'" wrapperClass="field col-3" label="12 MSI" v-model="processor_eglobal_bancomer.amex_credit_surcharge_12" />
                                                        
                                                        <FormInputNumber :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :prefix="'%'" wrapperClass="field col-3" label="18 MSI" v-model="processor_eglobal_bancomer.amex_credit_surcharge_18" />
                                                        <FormInputNumber :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :prefix="'%'" wrapperClass="field col-3" label="24 MSI" v-model="processor_eglobal_bancomer.amex_credit_surcharge_24" />
                                                 
                                                    </div>
                                                     </TabPanel>
                                                          <TabPanel header="Afiliacion">
                                                    <div class="p-fluid formgrid grid">
                                                        <FormInputText wrapperClass="field col-12" label="# Credito" v-model="processor_eglobal_bancomer.amex_credit_rate_affiliation" />
                                                        <FormInputText wrapperClass="field col-3" label="#Q6" v-model="processor_eglobal_bancomer.amex_credit_surcharge_3_affiliation" />
                                                        <FormInputText v-show="false"  wrapperClass="field col-3" label="# 6 MSI" v-model="processor_eglobal_bancomer.amex_credit_surcharge_6_affiliation" />
                                                        <FormInputText v-show="false"  wrapperClass="field col-3" label="# 9 MSI" v-model="processor_eglobal_bancomer.amex_credit_surcharge_9_affiliation" />
                                                        <FormInputText  v-show="false" wrapperClass="field col-3" label="# 12 MSI" v-model="processor_eglobal_bancomer.amex_credit_surcharge_12_affiliation" />
                                                        <FormInputText  v-show="false" wrapperClass="field col-3" label="# 18 MSI" v-model="processor_eglobal_bancomer.amex_credit_surcharge_18_affiliation" />
                                                        <FormInputText v-show="false"  wrapperClass="field col-3" label="# 24 MSI" v-model="processor_eglobal_bancomer.amex_credit_surcharge_24_affiliation" />
                                                 
                                                    </div>
                                                     </TabPanel>
                                                        </TabView>
                                                         
                                                   
                                                </Fieldset>
                                            </div>
                                        </div>
                                    </Fieldset>
                                </div>
                                <br />
                                <div class="col-12" style="margin-top: 1rem">
                                    <Fieldset :toggleable="true" legend="E GLOBAL BANAMEX" :collapsed="true">
                                        <div class="p-fluid formgrid grid">
                                            <Button @click="copyBanamex" label="Copiar datos de PROSA" icon="pi pi-copy" />
                                            <Divider />
                                            <FormInputText wrapperClass="field col-4" v-model="processor_eglobal_banamex.merchantID" label="Merchant ID" />
                                            <FormInputText wrapperClass="field col-4" v-model="processor_eglobal_banamex.merchantKeyId" label="Merchant Key ID" />
                                            <FormInputText wrapperClass="field col-4" v-model="processor_eglobal_banamex.merchantsecretKey" label="Merchant Secret Key" />
                                            <FormDropdown
                                                wrapperClass="field col-4"
                                                v-model="processor_eglobal_banamex.useMetaKey"
                                                label="Use Metakey"
                                                optionLabel="text"
                                                optionValue="value"
                                                :options="[
                                                    { value: 'true', text: 'Si' },
                                                    { value: 'false', text: 'No' },
                                                ]"
                                            />
                                            <FormDropdown
                                                wrapperClass="field col-4"
                                                v-model="processor_eglobal_banamex.runEnvironment"
                                                label="Environment"
                                                optionLabel="text"
                                                optionValue="value"
                                                :options="[
                                                    { value: 'apitest.cybersource.com', text: 'Pruebas' },
                                                    { value: 'api.cybersource.com', text: 'Produccion' },
                                                ]"
                                            />
         
                                        <FormDropdown :wrapperClass="'col-12 field'" v-model="processor_eglobal_banamex.sponsor_bank" label="Banco Sponsor" :options="bank" :optionLabel="'marca'" :optionValue="'marca'" />
                                        </div>
                                        <div class="p-fluid formgrid grid">
                                            <div class="col-6">
                                                <Fieldset :toggleable="true">
                                                    <template #legend>
                                                        <img :src="require('../../../assets/icons/payment/VISA.png')" class="ddl-card-icon" />
                                                        <img :src="require('../../../assets/icons/payment/MASTERCARD.png')" class="ddl-card-icon" /> Visa / Mastercard
                                                    </template>
                                                    <TabView ref="tab">
                                                    <TabPanel header="Comision">
                                                    <div class="p-fluid formgrid grid">
                                                      <FormInputNumber :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :prefix="'%'" wrapperClass="field col-6" label="Debito" v-model="processor_eglobal_banamex.visa_mc_debit_rate" />
                                                        <FormInputNumber :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :prefix="'%'" wrapperClass="field col-6" label="Credito" v-model="processor_eglobal_banamex.visa_mc_credit_rate" />
                                                        <FormInputNumber :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :prefix="'%'" wrapperClass="field col-3" label="3 MSI" v-model="processor_eglobal_banamex.visa_mc_credit_surcharge_3" />
                                                        <FormInputNumber :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :prefix="'%'" wrapperClass="field col-3" label="6 MSI" v-model="processor_eglobal_banamex.visa_mc_credit_surcharge_6" />
                                                        <FormInputNumber :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :prefix="'%'" wrapperClass="field col-3" label="9 MSI" v-model="processor_eglobal_banamex.visa_mc_credit_surcharge_9" />
                                                        <FormInputNumber :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :prefix="'%'" wrapperClass="field col-3" label="12 MSI" v-model="processor_eglobal_banamex.visa_mc_credit_surcharge_12" />
                                                        
                                                        <FormInputNumber :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :prefix="'%'" wrapperClass="field col-3" label="18 MSI" v-model="processor_eglobal_banamex.visa_mc_credit_surcharge_18" />
                                                        <FormInputNumber :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :prefix="'%'" wrapperClass="field col-3" label="24 MSI" v-model="processor_eglobal_banamex.visa_mc_credit_surcharge_24" />
                                                   
                                                    </div>
                                                     </TabPanel>
                                                          <TabPanel header="Afiliacion">
                                                    <div class="p-fluid formgrid grid">
                                                        <FormInputText wrapperClass="field col-6" label="# Debito" v-model="processor_eglobal_banamex.visa_mc_debit_rate_affiliation" />
                                                        <FormInputText wrapperClass="field col-6" label="# Credito" v-model="processor_eglobal_banamex.visa_mc_credit_rate_affiliation" />
                                                        <FormInputText wrapperClass="field col-3" label="#Q6" v-model="processor_eglobal_banamex.visa_mc_credit_surcharge_3_affiliation" />
                                                        <FormInputText v-show="false" wrapperClass="field col-3" label="# 6 MSI" v-model="processor_eglobal_banamex.visa_mc_credit_surcharge_6_affiliation" />
                                                        <FormInputText v-show="false" wrapperClass="field col-3" label="# 9 MSI" v-model="processor_eglobal_banamex.visa_mc_credit_surcharge_9_affiliation" />
                                                        <FormInputText v-show="false" wrapperClass="field col-3" label="# 12 MSI" v-model="processor_eglobal_banamex.visa_mc_credit_surcharge_12_affiliation" />
                                                        <FormInputText v-show="false" wrapperClass="field col-3" label="# 18 MSI" v-model="processor_eglobal_banamex.visa_mc_credit_surcharge_18_affiliation" />
                                                        <FormInputText v-show="false" wrapperClass="field col-3" label="# 24 MSI" v-model="processor_eglobal_banamex.visa_mc_credit_surcharge_24_affiliation" />
                                                   
                                                    </div>
                                                     </TabPanel>
                                                        </TabView>
                                                
                                                </Fieldset>
                                            </div>
                                            <div class="col-6">
                                                <Fieldset :toggleable="true">
                                                    <template #legend> <img :src="require('../../../assets/icons/payment/AMERICANEXPRESS.png')" class="ddl-card-icon" /> American Express </template>
                                                         <TabView ref="tab">
                                                    <TabPanel header="Comision">
                                                    <div class="p-fluid formgrid grid">
                                                        <FormInputNumber :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :prefix="'%'" wrapperClass="field col-12" label="Credito" v-model="processor_eglobal_banamex.amex_credit_rate" />
                                                        <FormInputNumber :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :prefix="'%'" wrapperClass="field col-3" label="3 MSI" v-model="processor_eglobal_banamex.amex_credit_surcharge_3" />
                                                        <FormInputNumber :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :prefix="'%'" wrapperClass="field col-3" label="6 MSI" v-model="processor_eglobal_banamex.amex_credit_surcharge_6" />
                                                        <FormInputNumber :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :prefix="'%'" wrapperClass="field col-3" label="9 MSI" v-model="processor_eglobal_banamex.amex_credit_surcharge_9" />
                                                        <FormInputNumber :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :prefix="'%'" wrapperClass="field col-3" label="12 MSI" v-model="processor_eglobal_banamex.amex_credit_surcharge_12" />
                                                        <FormInputNumber :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :prefix="'%'" wrapperClass="field col-3" label="18 MSI" v-model="processor_eglobal_banamex.amex_credit_surcharge_18" />
                                                        <FormInputNumber :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :prefix="'%'" wrapperClass="field col-3" label="24 MSI" v-model="processor_eglobal_banamex.amex_credit_surcharge_24" />
                                                 
                                                    </div>
                                                     </TabPanel>
                                                          <TabPanel header="Afiliacion">
                                                    <div class="p-fluid formgrid grid">
                                                        <FormInputText   wrapperClass="field col-12" label="# Credito" v-model="processor_eglobal_banamex.amex_credit_rate_affiliation" />
                                                        <FormInputText wrapperClass="field col-3" label="#Q6" v-model="processor_eglobal_banamex.amex_credit_surcharge_3_affiliation" />
                                                        <FormInputText v-show="false" wrapperClass="field col-3" label="# 6 MSI" v-model="processor_eglobal_banamex.amex_credit_surcharge_6_affiliation" />
                                                        <FormInputText v-show="false" wrapperClass="field col-3" label="# 9 MSI" v-model="processor_eglobal_banamex.amex_credit_surcharge_9_affiliation" />
                                                        <FormInputText v-show="false" wrapperClass="field col-3" label="# 12 MSI" v-model="processor_eglobal_banamex.amex_credit_surcharge_12_affiliation" />
                                                        <FormInputText v-show="false" wrapperClass="field col-3" label="# 18 MSI" v-model="processor_eglobal_banamex.amex_credit_surcharge_18_affiliation" />
                                                        <FormInputText v-show="false" wrapperClass="field col-3" label="# 24 MSI" v-model="processor_eglobal_banamex.amex_credit_surcharge_24_affiliation" />
                                                 
                                                    </div>
                                                     </TabPanel>
                                                        </TabView>
                                                    
                                            
                                                </Fieldset>
                                            </div>
                                        </div>
                                    </Fieldset>
                                </div>
                                <br />
                                <div class="col-12" style="margin-top: 1rem">
                                    <Fieldset :toggleable="true" legend="TERMINAL FISICA MIT" :collapsed="true">
                                        <div class="p-fluid formgrid grid">
                                            <Button @click="copyMIT" label="Copiar datos de PROSA" icon="pi pi-copy" />
                                            <Divider />
                                        </div>
                                         <FormDropdown :wrapperClass="'col-12 field'" v-model="processor_mit.sponsor_bank" label="Banco Sponsor" :options="bank" :optionLabel="'marca'" :optionValue="'marca'" />
                                        <FormInputText wrapperClass="field col-4" v-model="processor_mit.income_tpv" label="Renta por terminal" />
                                        <FormInputText wrapperClass="field col-4" v-model="processor_mit.total_amount_tpv" label="Monto Total" />
                                        <div class="p-fluid formgrid grid">
                                            <div class="col-6">
                                                <Fieldset :toggleable="true">
                                                    <template #legend>
                                                        <img :src="require('../../../assets/icons/payment/VISA.png')" class="ddl-card-icon" />
                                                        <img :src="require('../../../assets/icons/payment/MASTERCARD.png')" class="ddl-card-icon" /> Visa / Mastercard
                                                    </template>

                                                               <TabView ref="tab">
                                                    <TabPanel header="Comision">
                                                    <div class="p-fluid formgrid grid">
                                                        <FormInputNumber :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :prefix="'%'" wrapperClass="field col-6" label="Debito" v-model="processor_mit.visa_mc_debit_rate" />
                                                        <FormInputNumber :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :prefix="'%'" wrapperClass="field col-6" label="Credito" v-model="processor_mit.visa_mc_credit_rate" />
                                                        <FormInputNumber :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :prefix="'%'" wrapperClass="field col-3" label="3 MSI" v-model="processor_mit.visa_mc_credit_surcharge_3" />
                                                        <FormInputNumber :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :prefix="'%'" wrapperClass="field col-3" label="6 MSI" v-model="processor_mit.visa_mc_credit_surcharge_6" />
                                                        <FormInputNumber :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :prefix="'%'" wrapperClass="field col-3" label="9 MSI" v-model="processor_mit.visa_mc_credit_surcharge_9" />
                                                        <FormInputNumber :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :prefix="'%'" wrapperClass="field col-3" label="12 MSI" v-model="processor_mit.visa_mc_credit_surcharge_12" />
                                                        <FormInputNumber :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :prefix="'%'" wrapperClass="field col-3" label="18 MSI" v-model="processor_mit.visa_mc_credit_surcharge_18" />
                                                        <FormInputNumber :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :prefix="'%'" wrapperClass="field col-3" label="24 MSI" v-model="processor_mit.visa_mc_credit_surcharge_24" />
                                                   
                                                    </div>
                                                     </TabPanel>
                                                          <TabPanel header="Afiliacion">
                                                    <div class="p-fluid formgrid grid">
                                                        <FormInputText wrapperClass="field col-6" label="# Debito" v-model="processor_mit.visa_mc_debit_rate_affiliation" />
                                                        <FormInputText wrapperClass="field col-6" label="# Credito" v-model="processor_mit.visa_mc_credit_rate_affiliation" />
                                                        <FormInputText wrapperClass="field col-3" label="#Q6" v-model="processor_mit.visa_mc_credit_surcharge_3_affiliation" />
                                                        <FormInputText v-show="false" wrapperClass="field col-3" label="# 6 MSI" v-model="processor_mit.visa_mc_credit_surcharge_6_affiliation" />
                                                        <FormInputText v-show="false" wrapperClass="field col-3" label="# 9 MSI" v-model="processor_mit.visa_mc_credit_surcharge_9_affiliation" />
                                                        <FormInputText v-show="false" wrapperClass="field col-3" label="# 12 MSI" v-model="processor_mit.visa_mc_credit_surcharge_12_affiliation" />
                                                        <FormInputText v-show="false" wrapperClass="field col-3" label="# 18 MSI" v-model="processor_mit.visa_mc_credit_surcharge_18_affiliation" />
                                                        <FormInputText v-show="false" wrapperClass="field col-3" label="# 24 MSI" v-model="processor_mit.visa_mc_credit_surcharge_24_affiliation" />
                                                   
                                                    </div>
                                                     </TabPanel>
                                                        </TabView>
                                                    
                                                </Fieldset>
                                            </div>
                                            <div class="col-6">
                                                <Fieldset :toggleable="true">
                                                    <template #legend> <img :src="require('../../../assets/icons/payment/AMERICANEXPRESS.png')" class="ddl-card-icon" /> American Express </template>
                                                         <TabView ref="tab">
                                                    <TabPanel header="Comision">
                                                    <div class="p-fluid formgrid grid">
                                                      <FormInputNumber :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :prefix="'%'" wrapperClass="field col-12" label="Credito" v-model="processor_mit.amex_credit_rate" />
                                                        <FormInputNumber :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :prefix="'%'" wrapperClass="field col-3" label="3 MSI" v-model="processor_mit.amex_credit_surcharge_3" />
                                                        <FormInputNumber :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :prefix="'%'" wrapperClass="field col-3" label="6 MSI" v-model="processor_mit.amex_credit_surcharge_6" />
                                                        <FormInputNumber :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :prefix="'%'" wrapperClass="field col-3" label="9 MSI" v-model="processor_mit.amex_credit_surcharge_9" />
                                                        <FormInputNumber :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :prefix="'%'" wrapperClass="field col-3" label="12 MSI" v-model="processor_mit.amex_credit_surcharge_12" />
                                                        <FormInputNumber :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :prefix="'%'" wrapperClass="field col-3" label="18 MSI" v-model="processor_mit.amex_credit_surcharge_18" />
                                                        <FormInputNumber :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :prefix="'%'" wrapperClass="field col-3" label="24 MSI" v-model="processor_mit.amex_credit_surcharge_24" />
                                                  
                                                    </div>
                                                     </TabPanel>
                                                          <TabPanel header="Afiliacion">
                                                    <div class="p-fluid formgrid grid">
                                                        <FormInputText wrapperClass="field col-12" label="# Credito" v-model="processor_mit.amex_credit_rate_affiliation" />
                                                        <FormInputText wrapperClass="field col-3" label="#Q6" v-model="processor_mit.amex_credit_surcharge_3_affiliation" />
                                                        <FormInputText v-show="false" wrapperClass="field col-3" label="# 6 MSI" v-model="processor_mit.amex_credit_surcharge_6_affiliation" />
                                                        <FormInputText v-show="false" wrapperClass="field col-3" label="# 9 MSI" v-model="processor_mit.amex_credit_surcharge_9_affiliation" />
                                                        <FormInputText v-show="false" wrapperClass="field col-3" label="# 12 MSI" v-model="processor_mit.amex_credit_surcharge_12_affiliation" />
                                                        <FormInputText  v-show="false" wrapperClass="field col-3" label="# 18 MSI" v-model="processor_mit.amex_credit_surcharge_18_affiliation" />
                                                        <FormInputText v-show="false" wrapperClass="field col-3" label="# 24 MSI" v-model="processor_mit.amex_credit_surcharge_24_affiliation" />
                                                  
                                                    </div>
                                                     </TabPanel>
                                                        </TabView>
                                                  
                                                </Fieldset>
                                            </div>
                                        </div>
                                    </Fieldset>
                                </div>
                                <div class="col-12" style="margin-top: 1rem">
                                    <Fieldset :toggleable="true" legend="MEDIOS VIRTUALES" :collapsed="true">
                                        <div class="p-fluid formgrid grid">
                                            <Button @click="copyLink" label="Copiar datos de PROSA" icon="pi pi-copy" />
                                            <Divider />
                                        </div>
                                          <FormDropdown :wrapperClass="'col-12 field'" v-model="processor_link.sponsor_bank" label="Banco Sponsor" :options="bank" :optionLabel="'marca'" :optionValue="'marca'" />
                                       
                                        <div class="p-fluid formgrid grid">
                                            <div class="col-6">
                                                <Fieldset :toggleable="true">
                                                    <template #legend>
                                                        <img :src="require('../../../assets/icons/payment/VISA.png')" class="ddl-card-icon" />
                                                        <img :src="require('../../../assets/icons/payment/MASTERCARD.png')" class="ddl-card-icon" /> Visa / Mastercard
                                                    </template>
                                                    <TabView ref="tab">
                                                    <TabPanel header="Comision">
                                                    <div class="p-fluid formgrid grid">
                                                      <FormInputNumber :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :prefix="'%'" wrapperClass="field col-6" label="Debito" v-model="processor_link.visa_mc_debit_rate" />
                                                        <FormInputNumber :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :prefix="'%'" wrapperClass="field col-6" label="Credito" v-model="processor_link.visa_mc_credit_rate" />
                                                  
                                                    </div>
                                                     </TabPanel>
                                                          <TabPanel header="Afiliacion">
                                                    <div class="p-fluid formgrid grid">
                                                         <FormInputText  wrapperClass="field col-6" label="# Debito" v-model="processor_link.visa_mc_debit_rate_affiliation" />
                                                        <FormInputText wrapperClass="field col-6" label="# Credito" v-model="processor_link.visa_mc_credit_rate_affiliation" />
                                                  
                                                    </div>
                                                     </TabPanel>
                                                        </TabView>
                                               
                                                </Fieldset>
                                            </div>
                                            <div class="col-6">
                                                <Fieldset :toggleable="true">
                                                    <template #legend> <img :src="require('../../../assets/icons/payment/AMERICANEXPRESS.png')" class="ddl-card-icon" /> American Express </template>
                                                        <TabView ref="tab">
                                                    <TabPanel header="Comision">
                                                    <div class="p-fluid formgrid grid">
                                                      <FormInputNumber :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :prefix="'%'" wrapperClass="field col-12" label="Credito" v-model="processor_link.amex_credit_rate_affiliation" />
                                                  
                                                    </div>
                                                     </TabPanel>
                                                          <TabPanel header="Afiliacion">
                                                    <div class="p-fluid formgrid grid">
                                                         <FormInputText  wrapperClass="field col-12" label="# Credito" v-model="processor_link.amex_credit_rate_affiliation" />
                                                  
                                                    </div>
                                                     </TabPanel>
                                                        </TabView>
                                                   
                                                </Fieldset>
                                            </div>
                                        </div>
                                    </Fieldset>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel header="Pago a Meses">
                            <p>Esta configuracion solo aplica para terminal virtual (Cybersource)</p>
                             <div class="p-fluid formgrid grid">
                                <FormInputSwitch wrapperClass="field col-2" label="3MSI" v-model="processor_months_configuration['three_months']" />
                                <FormInputSwitch wrapperClass="field col-2" label="6MSI" v-model="processor_months_configuration['six_months']" />
                                <FormInputSwitch wrapperClass="field col-2" label="9MSI" v-model="processor_months_configuration['nine_months']" />
                                <FormInputSwitch wrapperClass="field col-2" label="12MSI" v-model="processor_months_configuration['twelve_months']" />
                                <FormInputSwitch wrapperClass="field col-2" label="18MSI" v-model="processor_months_configuration['eighteen_months']" />
                                <FormInputSwitch wrapperClass="field col-2" label="24MSI" v-model="processor_months_configuration['twentyfour_months']" />
                             </div>
                        </TabPanel>
                         <TabPanel header="Ticket">
                             <div class="p-fluid formgrid grid">
                                <FormInputText wrapperClass="field col-6" v-model="ticket_configuration.sender" label="Correo de Envio" />
                                <FormInputText wrapperClass="field col-6" v-model="ticket_configuration.sender_password" type="password" label="Password" />
                                <FormInputText wrapperClass="field col-6" v-model="ticket_configuration.title" label="Titulo" />
                                <FormInputText wrapperClass="field col-6" v-model="ticket_configuration.cc" label="CC" />
                                <FormInputText wrapperClass="field col-4" v-model="ticket_configuration.host" label="Host" />
                                <FormInputNumber wrapperClass="field col-4" v-model="ticket_configuration.port" label="Puerto" />
                                <FormDropdown wrapperClass="field col-4" v-model="ticket_configuration.has_ssl" :optionLabel="'name'" :optionValue="'value'" :options="[{value: true, name: 'SI'},{value: false, name: 'NO'}]" label="SSL" />

                                <div class="col-12">
                                    <p>
                                        Puedes utilizar las siguientes constantes para hacer tu correo dinamico. <br>
                                     <strong>[Name]</strong>: Nombre de cliente <strong>[ID]</strong>: ID de la transaccion <strong>[Amount]</strong>: Importe de la tranasaccion 
                                     <strong>[Card]</strong>: Ultimos cuatro digitos de la tarjeta <strong>[Concept]</strong>: Concepto de la transaccion <strong>[Date]</strong>: Fecha de la transaccion
                                     <strong>[Branch]</strong>: Nombre de la sucursal donde se realizó <strong>[Company]</strong>: Nombre de la compañia donde se realizó 
                                     <strong>[User]</strong>: Usuario que realizó la transaccion
                                    </p>
                                </div>
                                <br>
                                <div class="col-12">
                                    <Editor v-model="ticket_configuration.html_body"/>
                                </div>
                             </div>
                        </TabPanel>
                    </TabView>
                </Panel>
            </div>
        </div>
    </div>
</template>

<script>
import Loader from '../../../components/general/Loader.vue';
import FormInputText from '../../../components/general/FormInputText.vue';
import FormInputNumber from '../../../components/general/FormInputNumber.vue';
import FormInputSwitch from '../../../components/general/FormInputSwitch.vue';

import { BWCybersourceBranchProcessor } from '../../../models/custom/business_wallet/BWCybersourceBranchProcessor';
import { BWCybersourceBranchConfigurationTicket } from '../../../models/custom/business_wallet/BWCybersourceBranchConfigurationTicket';
import { BWCybersourceInstallmentConfiguration } from '../../../models/custom/business_wallet/BWCybersourceInstallmentConfiguration';

import BasicFormToolbar from '../../../components/general/BasicFormToolbar.vue';
import FormDropdown from '../../../components/general/FormDropdown.vue';
import Session from '../../../mixins/sessionMixin';
import { ErrorToast, fillObject, Toast,satCatalogo } from '../../../utilities/General';

export default {
    mixins: [Session],
    props: {
        id: null,
        view: null,
    },
    data() {
        return {
            ticket_configuration: new BWCybersourceBranchConfigurationTicket(),
            processor_prosa: new BWCybersourceBranchProcessor(),
            processor_eglobal_bancomer: new BWCybersourceBranchProcessor(),
            processor_eglobal_banamex: new BWCybersourceBranchProcessor(),
            processor_mit: new BWCybersourceBranchProcessor(),
            processor_link: new BWCybersourceBranchProcessor(),
            processor_months_configuration: new BWCybersourceInstallmentConfiguration(),
            loading: false,
            bank: []
        };
    },
    components: { Loader, FormInputText, BasicFormToolbar, FormInputSwitch, FormDropdown, FormInputNumber },
    methods: {
        async save() {
            this.loading = true;
            try {
                if (this.processor_prosa.id) await this.processor_prosa.update();
                else {
                    let processor_prosa = await this.processor_prosa.save();
                    this.processor_prosa.id = processor_prosa.id;
                }

                if (this.processor_eglobal_bancomer.id) await this.processor_eglobal_bancomer.update();
                else {
                    let processor_eglobal_bancomer = await this.processor_eglobal_bancomer.save();
                    this.processor_eglobal_bancomer.id = processor_eglobal_bancomer.id;
                }

                if (this.processor_eglobal_banamex.id) await this.processor_eglobal_banamex.update();
                else {
                    let processor_eglobal_banamex = await this.processor_eglobal_banamex.save();
                    this.processor_eglobal_banamex.id = processor_eglobal_banamex.id;
                }

                if (this.processor_mit.id) await this.processor_mit.update();
                else {
                    let processor_mit = await this.processor_mit.save();
                    this.processor_mit.id = processor_mit.id;
                }

                if (this.processor_link.id) await this.processor_link.update();
                else {
                    let processor_link = await this.processor_link.save();
                    this.processor_link.id = processor_link.id;
                }

                if (this.processor_months_configuration.id) await this.processor_months_configuration.update();
                else {
                    let processor_months_configuration = await this.processor_months_configuration.save();
                    this.processor_months_configuration.id = processor_months_configuration.id;
                }

                //* Configuration ticket
                if (this.ticket_configuration.id) await this.ticket_configuration.update();
                else {
                    let ticket_configuration = await this.ticket_configuration.save();
                    this.ticket_configuration.id = ticket_configuration.id;
                }
                this.$toast.add(
                    new Toast({
                        summary: 'Configuracion de Procesador',
                        detail: 'Informacion guardada con exito',
                    })
                );
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        copyBancomer() {
            this.processor_eglobal_bancomer.visa_mc_debit_rate = this.processor_prosa.visa_mc_debit_rate;
            this.processor_eglobal_bancomer.visa_mc_credit_rate = this.processor_prosa.visa_mc_credit_rate;
            this.processor_eglobal_bancomer.amex_credit_rate = this.processor_prosa.amex_credit_rate;
            this.processor_eglobal_bancomer.visa_mc_credit_surcharge_3 = this.processor_prosa.visa_mc_credit_surcharge_3;
            this.processor_eglobal_bancomer.visa_mc_credit_surcharge_6 = this.processor_prosa.visa_mc_credit_surcharge_6;
            this.processor_eglobal_bancomer.visa_mc_credit_surcharge_9 = this.processor_prosa.visa_mc_credit_surcharge_9;
            this.processor_eglobal_bancomer.visa_mc_credit_surcharge_12 = this.processor_prosa.visa_mc_credit_surcharge_12;
            this.processor_eglobal_bancomer.visa_mc_credit_surcharge_18 = this.processor_prosa.visa_mc_credit_surcharge_18;
            this.processor_eglobal_bancomer.visa_mc_credit_surcharge_24 = this.processor_prosa.visa_mc_credit_surcharge_24;
            this.processor_eglobal_bancomer.amex_credit_surcharge_3 = this.processor_prosa.amex_credit_surcharge_3;
            this.processor_eglobal_bancomer.amex_credit_surcharge_6 = this.processor_prosa.amex_credit_surcharge_6;
            this.processor_eglobal_bancomer.amex_credit_surcharge_9 = this.processor_prosa.amex_credit_surcharge_9;
            this.processor_eglobal_bancomer.amex_credit_surcharge_12 = this.processor_prosa.amex_credit_surcharge_12;
            this.processor_eglobal_bancomer.amex_credit_surcharge_18 = this.processor_prosa.amex_credit_surcharge_18;
            this.processor_eglobal_bancomer.amex_credit_surcharge_24 = this.processor_prosa.amex_credit_surcharge_24;
            this.processor_eglobal_bancomer.merchantID = this.processor_prosa.merchantID;
            this.processor_eglobal_bancomer.runEnvironment = this.processor_prosa.runEnvironment;
            this.processor_eglobal_bancomer.portfolioID = this.processor_prosa.portfolioID;
            this.processor_eglobal_bancomer.useMetaKey = this.processor_prosa.useMetaKey;
            this.processor_eglobal_bancomer.merchantsecretKey = this.processor_prosa.merchantsecretKey;
            this.processor_eglobal_bancomer.merchantKeyId = this.processor_prosa.merchantKeyId;
        },
        copyBanamex() {
            this.processor_eglobal_banamex.visa_mc_debit_rate = this.processor_prosa.visa_mc_debit_rate;
            this.processor_eglobal_banamex.visa_mc_credit_rate = this.processor_prosa.visa_mc_credit_rate;
            this.processor_eglobal_banamex.amex_credit_rate = this.processor_prosa.amex_credit_rate;
            this.processor_eglobal_banamex.visa_mc_credit_surcharge_3 = this.processor_prosa.visa_mc_credit_surcharge_3;
            this.processor_eglobal_banamex.visa_mc_credit_surcharge_6 = this.processor_prosa.visa_mc_credit_surcharge_6;
            this.processor_eglobal_banamex.visa_mc_credit_surcharge_9 = this.processor_prosa.visa_mc_credit_surcharge_9;
            this.processor_eglobal_banamex.visa_mc_credit_surcharge_12 = this.processor_prosa.visa_mc_credit_surcharge_12;
            this.processor_eglobal_banamex.visa_mc_credit_surcharge_18 = this.processor_prosa.visa_mc_credit_surcharge_18;
            this.processor_eglobal_banamex.visa_mc_credit_surcharge_24 = this.processor_prosa.visa_mc_credit_surcharge_24;

            this.processor_eglobal_banamex.amex_credit_surcharge_3 = this.processor_prosa.amex_credit_surcharge_3;
            this.processor_eglobal_banamex.amex_credit_surcharge_6 = this.processor_prosa.amex_credit_surcharge_6;
            this.processor_eglobal_banamex.amex_credit_surcharge_9 = this.processor_prosa.amex_credit_surcharge_9;
            this.processor_eglobal_banamex.amex_credit_surcharge_12 = this.processor_prosa.amex_credit_surcharge_12;
            this.processor_eglobal_banamex.amex_credit_surcharge_18 = this.processor_prosa.amex_credit_surcharge_18;
            this.processor_eglobal_banamex.amex_credit_surcharge_24 = this.processor_prosa.amex_credit_surcharge_24;
            this.processor_eglobal_banamex.merchantID = this.processor_prosa.merchantID;
            this.processor_eglobal_banamex.runEnvironment = this.processor_prosa.runEnvironment;
            this.processor_eglobal_banamex.portfolioID = this.processor_prosa.portfolioID;
            this.processor_eglobal_banamex.useMetaKey = this.processor_prosa.useMetaKey;
            this.processor_eglobal_banamex.merchantsecretKey = this.processor_prosa.merchantsecretKey;
            this.processor_eglobal_banamex.merchantKeyId = this.processor_prosa.merchantKeyId;
        },
        copyMIT() {
            this.processor_mit.visa_mc_debit_rate = this.processor_prosa.visa_mc_debit_rate;
            this.processor_mit.visa_mc_credit_rate = this.processor_prosa.visa_mc_credit_rate;
            this.processor_mit.amex_credit_rate = this.processor_prosa.amex_credit_rate;
            this.processor_mit.visa_mc_credit_surcharge_3 = this.processor_prosa.visa_mc_credit_surcharge_3;
            this.processor_mit.visa_mc_credit_surcharge_6 = this.processor_prosa.visa_mc_credit_surcharge_6;
            this.processor_mit.visa_mc_credit_surcharge_9 = this.processor_prosa.visa_mc_credit_surcharge_9;
            this.processor_mit.visa_mc_credit_surcharge_12 = this.processor_prosa.visa_mc_credit_surcharge_12;
            this.processor_mit.visa_mc_credit_surcharge_18 = this.processor_prosa.visa_mc_credit_surcharge_18;
            this.processor_mit.visa_mc_credit_surcharge_24 = this.processor_prosa.visa_mc_credit_surcharge_24;

            this.processor_mit.amex_credit_surcharge_3 = this.processor_prosa.amex_credit_surcharge_3;
            this.processor_mit.amex_credit_surcharge_6 = this.processor_prosa.amex_credit_surcharge_6;
            this.processor_mit.amex_credit_surcharge_9 = this.processor_prosa.amex_credit_surcharge_9;
            this.processor_mit.amex_credit_surcharge_12 = this.processor_prosa.amex_credit_surcharge_12;
            this.processor_mit.amex_credit_surcharge_18 = this.processor_prosa.amex_credit_surcharge_18;
            this.processor_mit.amex_credit_surcharge_24 = this.processor_prosa.amex_credit_surcharge_24;
        },
        copyLink() {
            this.processor_link.visa_mc_debit_rate = this.processor_prosa.visa_mc_debit_rate;
            this.processor_link.visa_mc_credit_rate = this.processor_prosa.visa_mc_credit_rate;
            this.processor_link.amex_credit_rate = this.processor_prosa.amex_credit_rate;
            this.processor_link.visa_mc_credit_surcharge_3 = this.processor_prosa.visa_mc_credit_surcharge_3;
            this.processor_link.visa_mc_credit_surcharge_6 = this.processor_prosa.visa_mc_credit_surcharge_6;
            this.processor_link.visa_mc_credit_surcharge_9 = this.processor_prosa.visa_mc_credit_surcharge_9;
            this.processor_link.visa_mc_credit_surcharge_12 = this.processor_prosa.visa_mc_credit_surcharge_12;
            this.processor_link.visa_mc_credit_surcharge_18 = this.processor_prosa.visa_mc_credit_surcharge_18;
            this.processor_link.visa_mc_credit_surcharge_24 = this.processor_prosa.visa_mc_credit_surcharge_24;

            this.processor_link.amex_credit_surcharge_3 = this.processor_prosa.amex_credit_surcharge_3;
            this.processor_link.amex_credit_surcharge_6 = this.processor_prosa.amex_credit_surcharge_6;
            this.processor_link.amex_credit_surcharge_9 = this.processor_prosa.amex_credit_surcharge_9;
            this.processor_link.amex_credit_surcharge_12 = this.processor_prosa.amex_credit_surcharge_12;
            this.processor_link.amex_credit_surcharge_18 = this.processor_prosa.amex_credit_surcharge_18;
            this.processor_link.amex_credit_surcharge_24 = this.processor_prosa.amex_credit_surcharge_24;
        },
    },
    async mounted() {
        this.loading = true;
        try {
            try {
                this.bank = await satCatalogo(28);
            }catch {
                console.log('No hay banco')
            }
            this.ticket_configuration = new BWCybersourceBranchConfigurationTicket(this.session);
            this.processor_prosa = new BWCybersourceBranchProcessor(this.session);
            this.processor_eglobal_bancomer = new BWCybersourceBranchProcessor(this.session);
            this.processor_eglobal_banamex = new BWCybersourceBranchProcessor(this.session);
            this.processor_mit = new BWCybersourceBranchProcessor(this.session);
            this.processor_link = new BWCybersourceBranchProcessor(this.session);
            this.processor_months_configuration = new BWCybersourceInstallmentConfiguration(this.session);

            this.processor_prosa.processor_name = "PROSA";
            this.processor_eglobal_bancomer.processor_name = "E-GLOBAL BANCOMER";
            this.processor_eglobal_banamex.processor_name = "E-GLOBAL BANAMEX";
            this.processor_mit.processor_name = "MIT";
            this.processor_link.processor_name = "LINK";

            let prosa = await this.processor_prosa.retrieveByBranchAndProcessor();
            if (prosa.id) 
                this.processor_prosa = fillObject(this.processor_prosa, prosa);
            let eglobal_bancomer = await this.processor_eglobal_bancomer.retrieveByBranchAndProcessor();
            if (eglobal_bancomer.id) 
                this.processor_eglobal_bancomer = fillObject(this.processor_eglobal_bancomer, eglobal_bancomer);
            let eglobal_banamex = await this.processor_eglobal_banamex.retrieveByBranchAndProcessor();
            if (eglobal_banamex.id) 
                this.processor_eglobal_banamex = fillObject(this.processor_eglobal_banamex, eglobal_banamex);
            let mit = await this.processor_mit.retrieveByBranchAndProcessor();
            if (mit.id) 
                this.processor_mit = fillObject(this.processor_mit, mit);
            let link = await this.processor_link.retrieveByBranchAndProcessor();
            if (link.id) 
                this.processor_link = fillObject(this.processor_link, link);
            let ticket_configuration = await this.ticket_configuration.retrieveByBranch();
            if (ticket_configuration)
                this.ticket_configuration = fillObject(this.ticket_configuration, ticket_configuration);
            let processor_months_configuration =  await this.processor_months_configuration.retrieveByBranch();
            if (processor_months_configuration)
                this.processor_months_configuration = fillObject(this.processor_months_configuration, processor_months_configuration);

        } catch (error) {
            this.$toast.add(new ErrorToast(error));
        } finally {
            this.loading = false;
        }
    },
};
</script>

<style scoped lang="scss">
.table-header {
    display: flex;
    justify-content: space-between;
}

.confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 960px) {
    ::v-deep(.p-toolbar) {
        flex-wrap: wrap;

        .p-button {
            margin-bottom: 0.25rem;
        }
    }
}

.ddl-card-icon {
    width: 35px;
    margin-right: 10px;
}
</style>
