import {
    Abstract
} from './Abstract';

import axios from 'axios';
export class BWCybersourceBranchConfigurationTicket extends Abstract { 
    constructor(session) {
        super('BusinessWallet/CyberSource/Configuration/ticket', session);
        this.sender = null;
        this.sender_password = null;
        this.cc = null;
        this.title = null;
        this.html_body = null;
        this.host = null;
        this.port = null;
        this.has_ssl = false;
    }

    async retrieveByBranch() {
        let response = await axios.get(this.controller + "/branch" , {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch
            }
        });
        return response.data;
    }
}